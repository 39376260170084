<template>
  <div class="main">
    <div class="item__column mailing">
      <div class="item__column modal__bonus__label">
        <p>Сообщение</p>
        <textarea type="text" v-model="text"/>
      </div>
      <button
          type="submit"
          class="modal__bonus__btn item__pointer"
          @click="sendMailing()"
      >
        <p>Отправить рассылку</p>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Mailing",
  components: {

  },
  data() {
    return {
      text: ''
    }
  },
  methods: {
    sendMailing() {

    }
  },
  mounted() {
  },
  watch: {
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.mailing {
  textarea {
    padding: 10px;
    outline: none;
    border: 1px solid #ccc;
    margin-bottom: 30px;
  }
  button {
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    justify-content: center;
    background: black;
    p {
      margin-bottom: 0;
      font-weight: bold;
      color: white;
    }
  }
}
</style>
